import { PropsWithChildren } from "react";
import PlatformsThemeProviderClient from "./PlatformsThemeProviderClient";

export interface PlatformsThemeProviderProps extends PropsWithChildren {
  settings: any;
}

export function PlatformsThemeProvider({
  children,
  settings,
}: PlatformsThemeProviderProps): JSX.Element {
  return (
    <>
      <PlatformsThemeProviderClient settings={settings} />
      {children}
    </>
  );
}
