const PlatformsThemeProviderClient = ({ settings }: any) => (
  <>
    {settings && (
      <>
        <style global>{`
          :root {
            //color
            --primary-50: ${settings?.theme?.palette?.primary[50] as string};
            --primary-100: ${settings?.theme?.palette?.primary[100] as string};
            --primary-200: ${settings?.theme?.palette?.primary[200] as string};
            --primary-300: ${settings?.theme?.palette?.primary[300] as string};
            --primary-400: ${settings?.theme?.palette?.primary[400] as string};
            --primary-500: ${settings?.theme?.palette?.primary[500] as string};
            --primary-600: ${settings?.theme?.palette?.primary[600] as string};
            --primary-700: ${settings?.theme?.palette?.primary[700] as string};
            --primary-800: ${settings?.theme?.palette?.primary[800] as string};
            --primary-900: ${settings?.theme?.palette?.primary[900] as string};

            --secondary-50: ${
              settings?.theme?.palette?.secondary[50] as string
            };
            --secondary-100: ${
              settings?.theme?.palette?.secondary[100] as string
            };
            --secondary-200: ${
              settings?.theme?.palette?.secondary[200] as string
            };
            --secondary-300: ${
              settings?.theme?.palette?.secondary[300] as string
            };
            --secondary-400: ${
              settings?.theme?.palette?.secondary[400] as string
            };
            --secondary-500: ${
              settings?.theme?.palette?.secondary[500] as string
            };
            --secondary-600: ${
              settings?.theme?.palette?.secondary[600] as string
            };
            --secondary-700: ${
              settings?.theme?.palette?.secondary[700] as string
            };
            --secondary-800: ${
              settings?.theme?.palette?.secondary[800] as string
            };
            --secondary-900: ${
              settings?.theme?.palette?.secondary[900] as string
            };
            //spacing
            --spacing-px: ${settings?.theme?.spacing?.px as string};
            --spacing-0: ${settings?.theme?.spacing[0] as string};
            --spacing-0-5: ${settings?.theme?.spacing[0.5] as string};
            --spacing-1: ${settings?.theme?.spacing[1] as string};
            --spacing-1-5: ${settings?.theme?.spacing[1.5] as string};
            --spacing-2: ${settings?.theme?.spacing[2] as string};
            --spacing-2-5: ${settings?.theme?.spacing[2.5] as string};
            --spacing-3: ${settings?.theme?.spacing[3] as string};
            --spacing-3-5: ${settings?.theme?.spacing[3.5] as string};
            --spacing-4: ${settings?.theme?.spacing[4] as string};
            --spacing-5: ${settings?.theme?.spacing[5] as string};
            --spacing-6: ${settings?.theme?.spacing[6] as string};
            --spacing-7: ${settings?.theme?.spacing[7] as string};
            --spacing-8: ${settings?.theme?.spacing[8] as string};
            --spacing-9: ${settings?.theme?.spacing[9] as string};
            --spacing-10: ${settings?.theme?.spacing[10] as string};
            --spacing-11: ${settings?.theme?.spacing[11] as string};
            --spacing-12: ${settings?.theme?.spacing[12] as string};
            --spacing-14: ${settings?.theme?.spacing[14] as string};
            --spacing-16: ${settings?.theme?.spacing[16] as string};
            --spacing-20: ${settings?.theme?.spacing[20] as string};
            --spacing-24: ${settings?.theme?.spacing[24] as string};
            --spacing-28: ${settings?.theme?.spacing[28] as string};
            --spacing-32: ${settings?.theme?.spacing[32] as string};
            --spacing-36: ${settings?.theme?.spacing[36] as string};
            --spacing-40: ${settings?.theme?.spacing[40] as string};
            --spacing-44: ${settings?.theme?.spacing[44] as string};
            --spacing-48: ${settings?.theme?.spacing[48] as string};
            --spacing-52: ${settings?.theme?.spacing[52] as string};
            --spacing-56: ${settings?.theme?.spacing[56] as string};
            --spacing-60: ${settings?.theme?.spacing[60] as string};
            --spacing-64: ${settings?.theme?.spacing[64] as string};
            --spacing-72: ${settings?.theme?.spacing[72] as string};
            --spacing-80: ${settings?.theme?.spacing[80] as string};
            --spacing-96: ${settings?.theme?.spacing[96] as string};

            //border-radius
            --border-none: ${settings?.theme?.borderRadius.none as string};
            --border-sm: ${settings?.theme?.borderRadius.sm as string};
            --border-default: ${
              settings?.theme?.borderRadius.DEFAULT as string
            };
            --border-lg: ${settings?.theme?.borderRadius.lg as string};
            --border-full: ${settings?.theme?.borderRadius.full as string};

            //font-size
            --font-sm: ${settings?.theme?.fontSize.sm as string};
            --font-base: ${settings?.theme?.fontSize.base as string};
            --font-xl: ${settings?.theme?.fontSize.xl as string};
            --font-2xl: ${settings?.theme?.fontSize["2xl"] as string};
            --font-3xl: ${settings?.theme?.fontSize["3xl"] as string};
            --font-4xl: ${settings?.theme?.fontSize["4xl"] as string};
            --font-5xl: ${settings?.theme?.fontSize["5xl"] as string};

            //font-weight
            --font-thin: ${settings?.theme?.fontWeight.thin as string};
            --font-hairline: ${settings?.theme?.fontWeight.hairline as string};
            --font-extralight: ${
              settings?.theme?.fontWeight.extralight as string
            };
            --font-light: ${settings?.theme?.fontWeight.light as string};
            --font-normal: ${settings?.theme?.fontWeight.normal as string};
            --font-medium: ${settings?.theme?.fontWeight.medium as string};
            --font-semibold: ${settings?.theme?.fontWeight.semibold as string};
            --font-bold: ${settings?.theme?.fontWeight.bold as string};
            --font-extrabold: ${
              settings?.theme?.fontWeight.extrabold as string
            };
            --font-black: ${settings?.theme?.fontWeight.black as string};

            //border-width
            --border-w-default: ${
              settings?.theme?.borderWidth.DEFAULT as string
            };
            --border-w-0: ${settings?.theme?.borderWidth[0] as string};
            --border-w-2: ${settings?.theme?.borderWidth[2] as string};
            --border-w-3: ${settings?.theme?.borderWidth[3] as string};
            --border-w-4: ${settings?.theme?.borderWidth[4] as string};
            --border-w-6: ${settings?.theme?.borderWidth[6] as string};
            --border-w-8: ${settings?.theme?.borderWidth[8] as string};

            //boxShadow
            --box-shadow-sm: ${settings?.theme?.boxShadow.sm as string};
            --box-shadow-default: ${
              settings?.theme?.boxShadow.DEFAULT as string
            };
            --box-shadow-md: ${settings?.theme?.boxShadow.md as string};
            --box-shadow-lg: ${settings?.theme?.boxShadow.lg as string};
            --box-shadow-xl: ${settings?.theme?.boxShadow.xl as string};
            --box-shadow-2xl: ${settings?.theme?.boxShadow["2xl"] as string};
            --box-shadow-3xl: ${settings?.theme?.boxShadow["3xl"] as string};
            --box-shadow-inner: ${settings?.theme?.boxShadow.inner as string};
            --box-shadow-none: ${settings?.theme?.boxShadow.none as string};

            //letter-spacing
            --letter-tightest: ${
              settings?.theme?.letterSpacing.tightest as string
            };
            --letter-tighter: ${
              settings?.theme?.letterSpacing.tighter as string
            };
            --letter-tight: ${settings?.theme?.letterSpacing.tight as string};
            --letter-normal: ${settings?.theme?.letterSpacing.normal as string};
            --letter-wide: ${settings?.theme?.letterSpacing.wide as string};
            --letter-wider: ${settings?.theme?.letterSpacing.wider as string};
            --letter-widest: ${settings?.theme?.letterSpacing.widest as string};

            //font-family
            --font-sans: ${settings?.theme?.fontFamily.sans as string};
            --font-serif: ${settings?.theme?.fontFamily.serif as string};
            --font-mono: ${settings?.theme?.fontFamily.mono as string};
          }
        `}</style>
      </>
    )}
  </>
);
export default PlatformsThemeProviderClient;
